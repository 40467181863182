export const strataMasterIntegrationConstants = {
  GET_CONNECTION_STATUS: 'GET_STRATA_INTEGRATION_CONNECTION_STATUS',
  GET_IMPORT_LOGS: 'GET_STRATA_INTEGRATION_IMPORT_LOGS',
  GET_WIZARD_URL: 'GET_STRATA_INTEGRATION_WIZARD_URL',
  UPDATE_DATA_IMPORT_LOG_ID: 'UPDATE_STRATA_INTEGRATION_DATA_IMPORT_LOG_ID',
  UPDATE_IMPORT_STATUS: 'UPDATE_STRATA_INTEGRATION_IMPORT_STATUS',
  GET_FAILED_ITEM_DETAILS: 'GET_FAILED_ITEM_DETAILS',
  UPDATE_LOADING: 'UPDATE_STRATA_INTEGRATION_LOADING',
  IMPORT_STATUSES: {
    COMPLETED: 'completed',
    FAILED: 'failed',
    IN_PROGRESS: 'in_progress',
    NEW: 'new',
    STOPPED: 'stopped',
  },
};
