import { axiosInstance } from '../utils';
import { invoiceFundingConstants } from '../constants';

const constants = invoiceFundingConstants;

const getNewInvoiceFunding = invoice_id => async dispatch => {
  dispatch({ type: constants.SET_INVOICE_FUNDING_LOADING, payload: true });

  await axiosInstance
    .get(`${constants.BASE_PATH.replace(':invoice_id', invoice_id)}/new`)
    .then(res => {
      const { data } = res;
      dispatch({ type: constants.GET_INVOICE_FUNDING_SUCCESS, payload: data });
    })
    .catch(error => {
      dispatch({ type: constants.GET_INVOICE_FUNDING_FAILED, payload: { errorCode: error.response.status } });
    });
};

const getInvoiceFunding = (invoice_id, id) => async dispatch => {
  dispatch({ type: constants.SET_INVOICE_FUNDING_LOADING, payload: true });

  await axiosInstance
    .get(`${constants.BASE_PATH.replace(':invoice_id', invoice_id)}/${id}`)
    .then(res => {
      const { data } = res;
      dispatch({ type: constants.GET_INVOICE_FUNDING_SUCCESS, payload: data });
    })
    .catch(error => {
      dispatch({ type: constants.GET_INVOICE_FUNDING_FAILED, payload: { errorCode: error.response.status } });
    });
};

export const invoiceFundingActions = {
  getNewInvoiceFunding,
  getInvoiceFunding,
};
