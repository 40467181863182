export const invoiceFinancingContractorConstants = {
  BASE_PATH: '/v1/invoice_financing_contractors/:id',
  REGISTRATION_STEPS: {
    WELCOME: 'welcome',
    LICENSE_DETAILS: 'license-details',
    BANK_DETAILS: 'bank-details',
  },
  NEW_SEO: {
    title: 'Invoice Financing Registration ',
    description: 'Invoice Financing Registration ',
  },
  SHOW_SEO: {
    title: 'Invoice Financing Registration Status',
    description: 'Invoice Financing Registration Status',
  },
  GET_INVOICE_FINANCING_CONTRACTOR_SUCCESS: 'GET_INVOICE_FINANCING_CONTRACTOR_SUCCESS',
  GET_INVOICE_FINANCING_CONTRACTOR_FAILED: 'GET_INVOICE_FINANCING_CONTRACTOR_FAILED',
  SET_INVOICE_FINANCING_CONTRACTOR_LOADING: 'SET_INVOICE_FINANCING_CONTRACTOR_LOADING',
};
