export const bulkDocumentDownloadConstants = {
  BASE_PATH: '/v1/bulk_attachment_downloads',
  BULK_CATEGORIES_OPTIONS: [
    { value: 'all_categories', label: 'All Categories' },
    { value: 'all_categories_except_invoice', label: 'All Categories except Invoice' },
  ],
  INDEX_SEO: {
    title: 'Bulk Document Downloads',
    description: 'Bulk Document Downloads',
  },
  STATE_OPTIONS: [
    { value: 'completed', label: 'Completed' },
    { value: 'failed', label: 'Failed' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'scheduled', label: 'Scheduled' },
  ],
  GET_BULK_DOCUMENT_DOWNLOADS_SUCCESS: 'GET_BULK_DOCUMENT_DOWNLOADS_SUCCESS',
  GET_BULK_DOCUMENT_DOWNLOADS_FAILED: 'GET_BULK_DOCUMENT_DOWNLOADS_FAILED',
  SET_BULK_DOCUMENT_DOWNLOADS_LOADING: 'SET_BULK_DOCUMENT_DOWNLOADS_LOADING',
  SET_BULK_DOCUMENT_DOWNLOADS_FILTER: 'SET_BULK_DOCUMENT_DOWNLOADS_FILTER',
};
