export const jobConstants = {
  UPCOMING_WORK_EXCLUDED_STATUSES: ['Cancelled', 'Closed', 'Completed', 'Declined'],
  STATUSES: {
    approval_denied: 'Approval Denied',
    awaiting_approval: 'Awaiting Approval',
    cancelled: 'Cancelled',
    closed: 'Closed',
    completed: 'Completed',
    in_progress: 'In Progress',
    pending: 'Pending',
  },
  messages: {
    loadingWorks: 'Loading recent works...',
  },
};
