const CONST = {
  NO_ABN_TEXT: 'No ABN',
  NOT_AVAILABLE_TEXT: 'N/A',
};

export const getTotalsFromRowItems = rowItems => {
  if (!rowItems || rowItems.length === 0) return { total: 0, gst: 0 };

  const total = rowItems.reduce((a, b) => a + parseFloat(b.amount || 0), 0);
  const gst = rowItems.reduce((a, b) => a + parseFloat(b.gst || 0), 0);
  return { total, gst };
};

/**
 * Get total including GST, When provided selected GL Codes and Sorting GL Codes
 *
 * @param {Array} selectedCodes selected GL Codes
 * @param {Array} sortingTypeCodes Admin GL Codes or CWF GL Codes
 * @returns Total + GST
 */
export const getGLCodeTotalByType = (selectedCodes = [], sortingTypeCodes = []) => {
  if (!selectedCodes || !sortingTypeCodes) return 0;

  const sortedCodeIds = sortingTypeCodes.map(code => code.id);
  const selectedCodesByType = selectedCodes.filter(code => sortedCodeIds.includes(code.gl_code_id));
  const { total, gst } = getTotalsFromRowItems(selectedCodesByType);

  return total + gst || 0;
};

/**
 * This function retrieves the contractor name with a few validations. If a proper name is not available, it will return "N/A"
 *
 * @param {object} contractorDetails Contactor object with contractor details
 * @returns string
 */
export const getContractorName = contractorDetails => {
  if (contractorDetails?.abn === CONST.NO_ABN_TEXT || contractorDetails?.abn === CONST.NOT_AVAILABLE_TEXT || !contractorDetails?.name) {
    return 'N/A';
  }

  return contractorDetails?.name;
};
