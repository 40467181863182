import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_ERROR } from './types';
import { axiosInstance } from '../utils';
import { categoryConstants } from '../constants';

const getCategories = () => async dispatch => {
  try {
    dispatch({ type: GET_CATEGORIES });
    const { data } = await axiosInstance.get(categoryConstants.BASE_PATH);
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: data.categories });
  } catch (error) {
    console.error('Error @categories.js getCategories', error);
    dispatch({
      type: GET_CATEGORIES_ERROR,
      error,
    });
  }
};

const getCategoryOptions = (q = null, callback) => {
  const params = { q };

  axiosInstance
    .get(categoryConstants.BASE_PATH, { params })
    .then(res => callback(res.data.categories))
    .catch(() => callback([]));
};

export const categoryActions = {
  getCategories,
  getCategoryOptions,
};
