export const invoiceFundingConstants = {
  BASE_PATH: '/v1/invoices/:invoice_id/invoice_fundings',
  REGISTRATION_STEPS: {
    WELCOME: 'welcome',
    LICENSE_DETAILS: 'license-details',
    BANK_DETAILS: 'bank-details',
  },
  APPLICATION_STEPS: {
    CONTRACTOR_DETAILS: 'contractor-details',
    INVOICE_DETAILS: 'invoice-details',
    PAYMENT_SUMMARY: 'payment-summary',
    COMPLETED: 'completed',
  },
  BUSINESS_TYPE_OPTIONS: [
    { value: 'company', label: 'Company' },
    { value: 'individual', label: 'Individual' },
    { value: 'partnership', label: 'Partnership' },
    { value: 'soletrader', label: 'Sole Trader' },
    { value: 'trust', label: 'Trust' },
  ],
  NEW_SEO: {
    title: 'New Invoice Funding',
    description: 'New Invoice Funding',
  },
  REGISTER_SEO: {
    title: 'Register Invoice Financing',
    description: 'Register Invoice Financing',
  },
  SHOW_SEO: {
    title: 'Invoice Financing',
    description: 'Invoice Financing',
  },
  GET_INVOICE_FUNDING_SUCCESS: 'GET_INVOICE_FUNDING_SUCCESS',
  GET_INVOICE_FUNDING_FAILED: 'GET_INVOICE_FUNDING_FAILED',
  SET_INVOICE_FUNDING_LOADING: 'SET_INVOICE_FUNDING_LOADING',
  STATES: {
    'verifying': {
      key: 'verifying',
      label: 'Verifying Application...',
      description: 'Verify Application Profile',
    },
    'creating_pay_plan_offer': {
      key: 'creating_pay_plan_offer',
      label: 'Submitting Pay Plan Offer...',
      description: 'Submit Pay Plan Offer',
    },
    'creating_pay_plan': {
      key: 'creating_pay_plan',
      label: 'Submitting Pay Plan...',
      description: 'Submit Pay Plan',
    },
    'submitting_pay_order': {
      key: 'submitting_pay_order',
      label: 'Submitting Pay Order...',
      description: 'Submit Pay Order',
    },
    'requesting_paid': {
      key: 'requesting_paid',
      label: 'Request Payment...',
      description: 'Request Payment',
    },
  },
};
