import { axiosInstance } from '../utils';
import { locationConstants, shareConstants } from '../constants';

const getLocationOptions = (page = shareConstants.DEFAULT_PAGE, per_page = shareConstants.DEFAULT_PER_PAGE, q = null, callback) => {
  const params = { q, page, per_page };

  axiosInstance
    .get(locationConstants.BASE_PATH, { params })
    .then(res => callback(res.data))
    .catch(() => callback([]));
};

const getLocationOptionsForUpload = (page = shareConstants.DEFAULT_PAGE, per_page = shareConstants.DEFAULT_PER_PAGE, q = null, callback) => {
  const params = { q, page, per_page };

  axiosInstance
    .get(locationConstants.BASE_PATH, { params })
    .then(res => callback(res.data.map(d => ({ ...d, value: d.site_plan_id }))))
    .catch(() => callback([]));
};

export const locationActions = {
  getLocationOptions,
  getLocationOptionsForUpload,
};
