export const buildingInspectionSessionConstants = {
  BASE_PATH: '/v1/building_inspection_sessions',
  GET_SESSIONS: 'GET_BUILDING_INSPECTION_SESSIONS',
  GET_CURRENT_SESSION: 'GET_CURRENT_INSPECTION_SESSION',
  UPDATE_LIST_LOADING: 'UPDATE_INSPECTION_SESSIONS_LOADING',
  UPDATE_CURRENT_SESSION_LOADING: 'UPDATE_CURRENT_INSPECTION_SESSION_LOADING',
  MODAL_NAME: 'BUILDING_INSPECTION_SESSION',
  STATES: {
    SCHEDULED: 'scheduled',
    ONGOING: 'ongoing',
    PAUSED: 'paused',
    STOPPED: 'stopped',
    EXPIRED: 'expired',
  },
};
