import { axiosInstance } from '../utils';
import { invoiceFinancingContractorConstants } from '../constants';

const constants = invoiceFinancingContractorConstants;

const getNewInvoiceFinancingContractor = () => async dispatch => {
  dispatch({ type: constants.SET_INVOICE_FINANCING_CONTRACTOR_LOADING, payload: true });

  await axiosInstance
    .get(`${constants.BASE_PATH.replace(':id', 'new')}`)
    .then(res => {
      const { data } = res;
      dispatch({ type: constants.GET_INVOICE_FINANCING_CONTRACTOR_SUCCESS, payload: data });
    })
    .catch(error => {
      dispatch({ type: constants.GET_INVOICE_FINANCING_CONTRACTOR_FAILED, payload: { errorCode: error.response.status } });
    });
};

const getInvoiceFinancingContractor = id => async dispatch => {
  dispatch({ type: constants.SET_INVOICE_FINANCING_CONTRACTOR_LOADING, payload: true });

  await axiosInstance
    .get(`${constants.BASE_PATH.replace(':id', id)}`)
    .then(res => {
      const { data } = res;
      dispatch({ type: constants.GET_INVOICE_FINANCING_CONTRACTOR_SUCCESS, payload: data });
    })
    .catch(error => {
      dispatch({ type: constants.GET_INVOICE_FINANCING_CONTRACTOR_FAILED, payload: { errorCode: error.response.status } });
    });
};

export const invoiceFinancingContractorActions = {
  getNewInvoiceFinancingContractor,
  getInvoiceFinancingContractor,
};
