import { axiosInstance } from '../utils';
import { aprilPayIndustryConstants, shareConstants } from '../constants';

export const fetchAprilPayIndustryOptions = (page = shareConstants.DEFAULT_PAGE, per_page = shareConstants.DEFAULT_PER_PAGE, by_keyword = null, callback) => {
  const params = { by_keyword, page, per_page };

  axiosInstance
    .get(aprilPayIndustryConstants.BASE_PATH, { params })
    .then(res => callback(res.data))
    .catch(() => callback([]));
};

export const aprilPayIndustryActions = {
  fetchAprilPayIndustryOptions,
};
