export const documentImportConstants = {
  BASE_PATH: '/external_apps/strata_master/third_party_import_logs',
  GET_IMPORT_LOGS: 'GET_IMPORT_LOGS',
  GET_IMPORT_LOG_ITEMS: 'GET_IMPORT_LOG_ITEMS',
  SET_IMPORT_STATUS: 'SET_IMPORT_STATUS',
  SET_LOADING: 'SET_LOADING',
  MESSAGES: {
    INVALID_PLAN_TITLE: 'This plan is not available',
    INVALID_PLAN_MESSAGE: 'Make sure the name of the file in the SFTP folder matches the plan number',
    DOCUMENT_TRANSFERRING: 'The documents are currently being transferred.',
    DOCUMENT_IMPORT_DISCLAIMER: 'Note: Imports may not run during business hours but will progress out of hours',
  },
  STATUSES: {
    NEW: 'new',
    COMPLETED: 'completed',
  },
};
