export const strataMasterDataLogsConstants = {
  GET_RESOURCE_DATA_LOGS: 'GET_RESOURCE_DATA_LOGS',
  RESET_INIT_RESOURCE_DATA_LOGS: 'RESET_INIT_RESOURCE_DATA_LOGS',
  RESET_RESOURCE_DATA_LOGS: 'RESET_RESOURCE_DATA_LOGS',
  GET_ORGANISATIONS: 'GET_ORGANISATIONS',
  GET_AVAILABLE_RESOURCES: 'GET_AVAILABLE_RESOURCES',
  INIT_PER_PAGE: 50,
  INIT_FIRST_PAGE: 1,
  INIT_TOTAL_COUNT: 0,
};
