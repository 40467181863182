export const errorConstants = {
  '500': {
    title: 'Oops! Something went wrong.',
    message: 'Try to refresh this page or contact us if the problem persists.',
  },
  '404': {
    title: 'Oops! Page not found',
    message: 'Please make sure you have typed the current URL or switch to different agency if you can.',
  },
  '401': {
    title: 'Your authorization failed',
    message: 'Please try refreshing the page and fill the correct login details',
  },
  '403': {
    title: 'Forbidden - Access is denied',
    message: 'Please make sure you have typed the current URL or switch to different agency if you can.',
  },
};
