// CATEGORIES
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

// NOTIFICATIONS
export const GET_NOTIFICATION_OPTIONS = 'GET_NOTIFICATION_OPTIONS';
export const GET_NOTIFICATION_OPTIONS_SUCCESS = 'GET_NOTIFICATION_OPTIONS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_HAS_UNREAD_NOTIFICATIONS = 'GET_HAS_UNREAD_NOTIFICATIONS';
export const GET_HAS_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_HAS_UNREAD_NOTIFICATIONS_SUCCESS';
export const MARK_ALL_READ_SUCCESS = 'MARK_ALL_READ_SUCCESS';
export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_REQUEST_SUCCESS = 'NOTIFICATION_REQUEST_SUCCESS';
export const UNREAD_MESSAGES = 'UNREAD_MESSAGES';
export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const USER_NOTIFICATIONS_SUCCESS = 'USER_NOTIFICATIONS_SUCCESS';

// DMS
export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_DOCUMENTS_LOADING = 'SET_DOCUMENTS_LOADING';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_TOGGLE_VALUE = 'SET_TOGGLE_VALUE';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';
export const GET_SINGLE_DOCUMENT = 'GET_SINGLE_DOCUMENT';
export const GET_ALL_GL_CODES = 'GET_ALL_GL_CODES';
export const GET_CWF_GL_CODES = 'GET_CWF_GL_CODES';
export const GET_RECENT_GL_CODES = 'GET_RECENT_GL_CODES';
export const GET_ADMIN_GL_CODES = 'GET_ADMIN_GL_CODES';
export const GET_GROUP_CODES = 'GET_GROUP_CODES';
export const RESET_INVOICE = 'RESET_INVOICE';
export const SET_EXPORT_DOCUMENTS = 'SET_EXPORT_DOCUMENTS';
export const SET_SORT_OPTIONS = 'SET_SORT_OPTIONS';

// REPORTS
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORT_TYPES = 'GET_REPORT_TYPES';
export const GET_REPORT_TYPES_ERROR = 'GET_REPORT_TYPES_ERROR';
export const GET_REPORT_TYPES_SUCCESS = 'GET_REPORT_TYPES_SUCCESS';

// FILE UPLOAD
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';

// MESSAGES
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_CURRENT_MESSAGES = 'GET_CURRENT_MESSAGES';
export const GET_CURRENT_MESSAGES_SUCCESS = 'GET_CURRENT_MESSAGES_SUCCESS';
export const GET_FILTERED_MESSAGES_SUCCESS = 'GET_FILTERED_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';
export const ARCHIVE_CHAT_ROOM = 'ARCHIVE_CHAT_ROOM';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const SEND_NEW_OWN_MESSAGE = 'SEND_NEW_OWN_MESSAGE';
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING';
export const CHANGE_CURRENT_CONVERSATION = 'CHANGE_CURRENT_CONVERSATION';
export const ADD_NEW_CONVERSATION = 'ADD_NEW_CONVERSATION';
export const SET_CONVERSATION_FIELD = 'SET_CONVERSATION_FIELD';
export const SET_MESSAGES_SEARCH_VALUE = 'SET_MESSAGES_SEARCH_VALUE';
export const BROADCAST_DELETE_MESSAGE = 'BROADCAST_DELETE_MESSAGE';
export const UPDATE_DRAFT_MESSAGE = 'UPDATE_DRAFT_MESSAGE';

// COUNTER (BOILERPLATE)
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

// WEATHER (BOILERPLATE)
export const WEATHER_DATA = 'WEATHER_DATA';

// TAGS LIBRARY
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';

// AUTH
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const STORE_LOGIN_LOCATION = 'STORE_LOGIN_LOCATION';
export const CLEAR_LOGIN_LOCATION = 'CLEAR_LOGIN_LOCATION';

// BUILDING PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_ACTIONS = 'GET_ACTIONS';
export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS';
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export const RESET_BUILDING_PROFILE = 'RESET_BUILDING_PROFILE';
export const GET_LOT_NUMBERS = 'GET_LOT_NUMBERS';
export const GET_LOT_NUMBERS_SUCCESS = 'GET_LOT_NUMBERS_SUCCESS';
export const GET_LOT_NUMBERS_ERROR = 'GET_LOT_NUMBERS_ERROR';

// USERS
export const GET_ORG_USERS = 'GET_ORG_USERS';
export const GET_SP_CONTACTS = 'GET_SP_CONTACTS';
// ATTACHMENTS
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';

//GOOD DATA
export const GET_DASHBOARDS = 'GET_DASHBOARDS'
export const CREATE_DASHBOARD = 'CREATE_DASHBOARD'

