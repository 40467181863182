import { axiosInstance } from '../utils';
import { bulkDocumentDownloadConstants } from '../constants';

const constants = bulkDocumentDownloadConstants;

const getBulkDocumentDownloads = (params = null) => async dispatch => {
  dispatch({ type: constants.SET_BULK_DOCUMENT_DOWNLOADS_LOADING, payload: true });

  await axiosInstance
    .get(constants.BASE_PATH, { params })
    .then(res => {
      const { data } = res;
      dispatch({
        type: constants.GET_BULK_DOCUMENT_DOWNLOADS_SUCCESS,
        payload: { list: data.bulk_attachment_downloads, total_count: data.meta.total_count },
      });
    })
    .catch(error => {
      dispatch({ type: constants.GET_BULK_DOCUMENT_DOWNLOADS_FAILED, payload: { errorCode: error.response.status } });
    });
};

export const bulkDocumentDownloadActions = {
  getBulkDocumentDownloads,
};
