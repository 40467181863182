import { hotjar } from 'react-hotjar';

export const hotjarInit = () => {
  if (process.env.HOTJAR_ID) {
    try {
      hotjar.initialize(process.env.HOTJAR_ID, 6);
    } catch (error) {
      console.log(error);
    }
  }
};
